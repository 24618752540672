/* Cubic Bezier Transition */
/***
Login page
***/
/* logo page */
.login {
  background-color: #a0b4c9 !important; }

.login .logo {
  margin: 60px auto 20px auto;
  padding: 15px;
  text-align: center; }

.login .content {
  background-color: #fff;
  width: 360px;
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px; }

.login .content h3 {
  color: #000; }

.login .content h4 {
  color: #555; }

.login .content p {
  color: #222; }

.login .content .login-form,
.login .content .forget-form {
  padding: 0px;
  margin: 0px; }

.login .content .input-icon {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .login .content .input-icon .form-control {
    border-left: 0; }

.login .content .forget-form {
  display: none; }

.login .content .register-form {
  display: none; }

.login .content .form-title {
  font-weight: 300;
  margin-bottom: 25px; }

.login .content .form-actions-no-border {
  background-color: #fff;
  clear: both;
  border: 0px;
  padding: 0px 30px 25px 30px;
  margin-left: -30px;
  margin-right: -30px; }

.login .content .form-actions {
  background-color: #fff;
  clear: both;
  border: 0px;
  border-bottom: 1px solid #eee;
  padding: 0px 30px 25px 30px;
  margin-left: -30px;
  margin-right: -30px; }

.login .content .forget-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 20px; }

.login .content .register-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0px; }

.login .content .form-actions .rememberme {
  margin-top: 8px;
  display: inline-block; }

.login .content .form-actions .btn {
  margin-top: 1px; }

.login .content .forget-password {
  margin-top: 25px; }

.login .content .create-account {
  border-top: 1px dotted #eee;
  padding-top: 10px;
  margin-top: 15px; }

.login .content .create-account a {
  display: inline-block;
  margin-top: 5px; }

/* select2 dropdowns */
.login .content .select2-container {
  border-left: 2px solid #44B6AE !important; }

.login .content .select2-container .select2-choice {
  border-left: none !important; }

.login .content .select2-container i {
  display: inline-block;
  position: relative;
  color: #ccc;
  z-index: 1;
  top: 1px;
  margin: 4px 4px 0px -1px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center; }

.login .content .has-error .select2-container i {
  color: #b94a48; }

.login .content .select2-container a span {
  font-size: 13px; }

.login .content .select2-container a span img {
  margin-left: 4px; }

/* footer copyright */
.login .copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: #999;
  font-size: 13px; }

@media (max-width: 480px) {
  /***
  Login page
  ***/
  .login .logo {
    margin-top: 10px; }
  .login .content {
    width: 280px; }
  .login .content h3 {
    font-size: 22px; }
  .login .checkbox {
    font-size: 13px; } }
